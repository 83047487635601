import React, { useEffect, useState, useCallback } from "react";

import CosmosDirectory from "../CosmosDirectory";

import SimpleCard from '../components/SimpleCard';
import CryptoCard from '../components/CryptoCard';

import Word from '../assets/elements/Animations/estrellas2.json';
import Security from '../assets/elements/Animations/Escudo.json';
import Servers from '../assets/elements/Animations/contenedores.json';
import Triangle from '../assets/elements/Animations/piramide.json';
import Lines from '../assets/elements/SVG ASSETS/YellowLines.svg';
import Circle from '../assets/elements/SVG ASSETS/Circles.svg';
import Arrow from '../assets/elements/SVG ASSETS/chevron_down.svg';
import Lottie from "react-lottie";

import Contact from '../components/Contact';
import cryptos from '../utility/cryptos.json';

function Dashboard() {

  const [chains, setChains] = useState()
  const [chainAprs, setChainAprs] = useState({})

  const directory = CosmosDirectory()

  useEffect(() => {
      if(!chains){
        getChains()
      }
    }, []);

  function getChains(){
    return directory.getChains()
      .then(chains => {
        setChains(chains.chains)
    })
  };

  useEffect(() => {
    if (!chains) return
  
    const mapping = {}

    chains.map((chain) => {
      const chainName = chain.name
      try {
        mapping[chainName] = chain.params.calculated_apr
      } catch(err) {
        mapping[chainName] = 0.0
      }
    })

    setChainAprs(mapping)
  }, [chains]);

  function toPc(number) {
    return Math.round((number + Number.EPSILON) * 100)
  }

  const getApr = useCallback((crypto_json) => {
    const normalizedName = crypto_json.blockchain_name.replace(/\s+/g, "").toLowerCase()
    const apr = chainAprs[normalizedName]
    if (!apr) {
      return " not available"
    } else {
      return `${toPc(apr)}%`
    }
  }, [chainAprs])

  return (
    <div className="App">
      <div className="container pt-20">
        <h1 className="titles">EARN
          <br />
          <div className="titles-yellow">STAKING REWARDS</div>
          <br />
          ON YOUR CRYPTO ASSETS
          <br />
          WITH CONFIDENCE
        </h1>
        <p className="py-8 sub-title">Our professional staking services support the future
          of decentralised ﬁnance, gaming and trade</p>
        <div className='flex justify-center'>
          <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: Word,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                />
        </div>
        <a href='#cryptos'><img className='arrow-home' src={Arrow} alt='arrow_down'/></a>
        <div id='Benefits' className='flex flex-col lg:flex-row lg:px-10 lg:py-11'>
          <SimpleCard title="Security focused." text="We provide high quality, secure, and professionally managed infrastructure so that you can be conﬁdent in your staking rewards, day after day." src={Security} />
          <SimpleCard title="Only the best networks." text="We only run validator nodes on networks that we believe to be the best candidates for successful blockchains and products. We stake our own assets as proof." src={Triangle} />
          <SimpleCard title="Slash protected." text="You can be conﬁdent in your investment with our 100% soft-slash protection. We will refund any funds lost to a downtime slash." src={Servers} />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row lg:py-11'>
        <img src={Lines} alt='Galaxy' />
        <div className='flex flex-col pt-10 lg:px-10 lg:mx-5'>
          <div className='flex flex-col xl:flex-row px-5'>
            <p className='flex justify-start p-sub-yellow '>Our Supported Networks</p> <p className='flex justify-start p-sub-grey hidden-component'>Stake with the best</p>
          </div>
          <div className='flex flex-col lg:flex-row px-5 py-5'>
            <p className='text-left p1-gray our-professional'>Stake your tokens on any of our supported networks. By staking your tokens, you help to secure the network from bad actors and receive staking rewards.</p>
          </div>
        </div>
      </div>
      <div className='mt-10 md:mt-0'>
        <div id='cryptos' className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <p className='vertical-text text-left mb-10'>
            {"OUR "}
            <br className='hidden-component' />
            NETWORKS</p>
          <div className='col-span-2 lg:grid grid-cols-3 flex-1 mt-10 md:mt-0 lg:pr-10'>
            {chains && cryptos.map(item => {
              return <CryptoCard key={item.blockchain_name} name={item.blockchain_name} description={item.description_short} APR={getApr(item)} img={item.blockchain_logo} APRStatus={item.APRStatus} stake={item.stake} link={item.stake_now_button_link} />
            })}
            
          </div>
        </div>
      </div>
        <div className='container-bg2 items-center'>
          <img src={Circle} alt='Circles' />
          <div className='text-over flex flex-row items-center'>{"START STAKING "} <p className='pl-2 yellow'>{" NOW"}</p></div>
        </div>
      <Contact/>
    </div>
  );
}


export default Dashboard;
