import Contact from "../components/Contact";
export default function Policy() {

    return (
        <div className="App">
            <div className='container policy'>
                <p id="downtime-slashing" className='policies-sub-tittle text-left'>
                    Downtime Slashing
                </p>
                <p className='policies-text text-left pb-5'>
                Kingnodes is committed to ensuring high validator uptime for our delegators. To uphold this commitment, we are proud to offer validator downtime slashing protection to our delegators. This protection applies in the event of downtime slashing, which occurs when the validator fails to provide the required number of block signatures specified by the blockchain's on-chain parameters, resulting in a slashable event. The refund amount will be determined by multiplying the delegator's stake by the slashing fraction imposed by the blockchain. 
                </p>
                <p className='policies-text text-left pb-5'>
                The following exemptions apply
                </p>
                <p className='policies-text text-left'>
                Blockchains:
                </p>
                <ul className='policies-ul text-left pb-5'>
                    <li>None</li>
                </ul>
                <p className='policies-text text-left'>
                    Delegator Types:
                </p>
                <ul className='policies-ul text-left pb-5'>
                    <li>Foundation delegation wallets</li>
                    <li>Blockchain delegation program wallets</li>
                </ul>
                <p className='policies-text text-left'>
                    This policy does not cover other types of slashing, including but not limited to:
                </p>
                <ul className='policies-ul text-left pb-16'>
                    <li>Double sign (tombstone)</li>
                    <li>Oracle vote misses</li>
                    <li>Bridge orchestrator vote misses</li>
                </ul>
            </div>

        </div>
    );
}