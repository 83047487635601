import React from "react";
import Logo from "../assets/logo/LogoKingN.svg";

import Social from "./Social";
import cryptos from '../utility/cryptos.json'

export default function Footer() {
    return (
        <div className="footer w-full">
            <div className="flex flex-col lg:flex-row pb-0 lg:pb-10">
                <div className="flex flex-1 flex-col justify-start pb-10">
                    <a
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                        className="lg:pb-10 pb-5 justify-start flex"
                    >
                        <img className="logo" src={Logo} alt='medium' />
                    </a>
                    <p className="text-left text text-logo">
                        Earn staking rewards on your crypto assets with conﬁdence
                        <br />
                        <br />
                        info@kingnodes.com
                    </p>
                </div>
                <div className="flex flex-1 flex-col justify-start pb-10">
                    <p className="text-left title px-0 lg:px-10 lg:pb-10">
                        Live Networks
                    </p>
                    <div className="grid grid-cols-2 w-full">
                        {cryptos.map(e => {
                            return (<a key={e.blockchain_name} href={"/details/" + e.blockchain_name} className="text-left text2 px-0 lg:px-10 py-2">{e.blockchain_name}</a>)
                        })}
                    </div>
                </div>
                <div className="flex flex-1 flex-col justify-start pb-10">
                    <p className="text-left title px-0 lg:px-10 lg:pb-10">
                        Policies
                    </p>
                    <div className="grid grid-cols-1  w-full">
                        {policies.map(e => {
                            return (<a key={e.name} href={e.link} className="text-left text2 px-0 lg:px-10 py-2">{e.name}</a>)
                        })}
                    </div>
                </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row justify-between pt-0 lg:pt-10 items-center">
                <p className="text-center lg:text-left bottom-title">
                    © Kingnodes 2022
                </p>
                <div className='flex flex-1 nav-links pb-8 lg:pb-0'>
                    <Social />
                </div>
            </div>
        </div>
    );
}

let policies = [
    {
        name: 'Downtime Slashing',
        link: '/policy/#downtime-slashing'
    },
]