import React from "react";
import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import SignalGreen from '../assets/elements/SVG ASSETS/green.svg'
import SignalRed from '../assets/elements/SVG ASSETS/red.svg'
import { Button, CardFooter } from "@material-tailwind/react";
import { Link } from "react-router-dom";

import { iconsDictionary } from "../utility/iconsDictionary";

export default function CryptoCard(props) {

    return (
        <Card className="bg-transparent crypto-card flex flex-col">
            <CardImage
                className='card-img2'
                src={iconsDictionary[props.img]}
                alt={"Card Image" + props.name}
            />
            <CardBody>
                <p className="card-title text-left" color="withe">{props.name}</p>
                <p className="card-description text-left py-5">
                    {props.description}
                </p>
                {props.stake ?
                    <p className="flex flex-row card-description text-left py-3" color="gray">
                        {"APR " + props.APR} {props.APRStatus !== undefined ? <img className="px-3" src={props.APRStatus === 0 ? SignalGreen : SignalRed} alt="" /> : ""}
                    </p>
                    :
                    <p className="flex flex-row card-description text-left py-3" color="gray">
                        {' '}
                    </p>
                }
            </CardBody>
            <CardFooter className='h-full flex items-end'>
                {
                    props.stake ?
 //                       <Link target={'_blank'} to={"/details/" + props.name}>
                        <Link target={'_blank'} to={{pathname: props.link}}>
                            <Button
                                buttonType="filled"
                                size="lg"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                className='button-card'
                            >
                                Stake Now
                            </Button>
                        </Link>
                        :
                        <Button
                            buttonType="filled"
                            size="lg"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            disabled={true}
                            className="button-card-disabled"
                        >
                            Soon
                        </Button>
                }
            </CardFooter>
        </Card>
    );
}