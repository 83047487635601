import React from "react";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import Lottie from "react-lottie";

export default function SimpleCard(props) {
    return (
        <div className="simple-card">
            <Card className="bg-transparent">
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: props.src,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    className='card-img'
                    width='9.1rem'
                />
                <CardBody>
                    <p className="card-title-1 pb-7" color="withe">{props.title}</p>
                    <p className="card-description">
                        {props.text}
                    </p>
                </CardBody>
            </Card>
        </div>
    );
}
