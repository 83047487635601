import NavLink from '@material-tailwind/react/NavLink';
import Discord from '../assets/elements/SVG ASSETS/discord.svg';
import Github from '../assets/elements/SVG ASSETS/github.svg';
import Twitter from '../assets/elements/SVG ASSETS/twitter.svg';
import Telegram from '../assets/elements/SVG ASSETS/telegram.svg';
import Medium from '../assets/elements/SVG ASSETS/medium.svg';

export default function Social() {

    return (<>
        <NavLink target="blank" className="px-0 nav-icons" href="https://github.com/kingnodes" ripple="light">
            <img className='nav-icons' src={Github} alt='github' />
        </NavLink>
        <NavLink target="blank" href="https://discord.com/invite/TBuaH3GYpN" ripple="light">
            <img className='nav-icons' src={Discord} alt='discord' />
        </NavLink>
        <NavLink target="blank" href="https://twitter.com/kingnodes" ripple="light">
            <img className='nav-icons' src={Twitter} alt='twitter' />
        </NavLink>
        <NavLink target="blank" href="https://t.me/kingnodes" ripple="light">
            <img className='nav-icons' src={Telegram} alt='telegram' />
        </NavLink>
    </>)
}
