import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Homepage from './pages/Homepage'
import Details from './pages/Details'
import Policy from "./pages/Policy"
import ScrollTop from "./utility/ScrollTop";

import Header from "./components/header";
import Footer from "./components/Footer";
import './App.css';
import "@material-tailwind/react/tailwind.css"

function App() {
  return (
    <div>
      <Header/>
        <ScrollTop/>
        <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/details/:blockchainName" component={Details} />
        <Route path="/policy" component={Policy} />
        <Route path="*" component={()=>{return <Redirect to="/" />}}/>
        </Switch>
      <Footer/>
    </div>
  );
}

export default App;
