import { useEffect } from 'react';
import cryptos from '../utility/cryptos.json'

import DropItem from './DropItem';


function DropdownBox(props) {

    const handleKeyDown = (event) => {
        if(event.target.id!=='openNav'){
            props.setOpen(false)
        }
      };
    
      useEffect(() => {
        window.addEventListener('click', handleKeyDown);
    
        return () => {
          window.removeEventListener('click', handleKeyDown);
        };
      }, []);
    
    return (
        <div className={'flex flex-row justify-center dropdown ' + (props.open ? ' down' : ' up')}>
            <div id='menu' className='flex-1 grid grid-cols-2 p-4 gap-4rem'>
                {cryptos.map(e=>{
                   return <DropItem key={e.blockchain_name} name={e.blockchain_name} description={e.description_short} APR={e.expected_commission_rate} src={e.blockchain_logo} APRStatus={e.APRStatus} stake={e.stake} link={e.stake_now_button_link}/>      
               })}
            </div>
        </div>
    );
}

export default DropdownBox;