import Social from "./Social"

export default function Contact() {
    return (
            <div  id='ContactUs' className='container-bg grid grid-cols-1 lg:grid-cols-3 gap-4'>
                <div>
                </div>
                <div className="col-span-2 contact flex items-center">
                    <div>
                        <div className='text-center lg:text-left titles-max pb-2rem'>
                            {"GET "}
                            <br className='hidden-component-contact' />
                            {"IN "}
                            <p className="titles-yellow-max">{"TOUCH"}</p>
                            <br />
                            {" WITH US "}
                        </div>
                        <div className='sub-title-gray text-center lg:text-left pb-2-5rem'>
                            Kingnodes are highly active in the communities we operate in. We are available 24/7 and provide free support helping community in ofﬁcial chats
                        </div>
                        <div className='flex justify-contact-items justify-start contact-items'>
                            <Social />
                        </div>
                    </div>
                </div>
            </div>
    )
}