import React from "react";
import Card from "@material-tailwind/react/Card";

export default function TextBox(props) {
    return (
        <div className="flex justify-center container-text-box">
            <Card className="text-box">
                    <p className="tb-title pb-3 flex" color="withe">{props.title}</p>
                    <p className="tb-description">
                        {props.text}
                    </p>
            </Card>
        </div>
    );
}