import { Link } from 'react-router-dom';
import SignalGreen from '../assets/elements/SVG ASSETS/green.svg'
import SignalRed from '../assets/elements/SVG ASSETS/red.svg'
import arrow from '../assets/elements/SVG ASSETS/arrowdropdown.svg'
import { iconsDictionary } from '../utility/iconsDictionary';

function DropItem(props) {
    if (props.stake) {
        return (
//            <Link to={"/details/" + props.name} id="Item" >
            <Link target={'_blank'} to={{pathname: props.link}} id="Item" >
                <div className='flex flex-row drop-item w-full'>
                    <div className=''>
                        <img className='dd-item-icon' src={iconsDictionary[props.src]} alt={'icon ' + props.name} />
                    </div>
                    <div className='flex flex-col grow'>
                        <p className="dd-item-title text-left" color="withe">{props.name}</p>

                        <p className="flex flex-row dd-item-description text-left" color="gray">
                            {"APR " + props.APR} {props.APRStatus !== undefined ? <img className="px-3" src={props.APRStatus === 0 ? SignalGreen : SignalRed} alt="" /> : ""}
                        </p>
                    </div>
                    <div className='flex items-center'>
                        <img src={arrow} alt={'arrow ' + props.name} />
                    </div>
                </div>
            </Link>
        );
    }
    else {
        return (
            <div id="Item" >
                <div className='flex flex-row drop-item w-full'>
                    <div className=''>
                        <img className='dd-item-icon' src={iconsDictionary[props.src]} alt={'icon ' + props.name} />
                    </div>
                    <div className='flex flex-col grow'>
                        <p className="dd-item-title text-left" color="withe">{props.name + ' (Soon)'}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default DropItem;