import Juno from '../assets/elements/SVG ASSETS/juno.svg'
import Stargaze from '../assets/elements/SVG ASSETS/stargaze.svg'
import Ki from '../assets/elements/SVG ASSETS/ki.svg'
import Chihuahua from '../assets/elements/SVG ASSETS/chihuahua.svg'
import Omniflix from '../assets/elements/SVG ASSETS/omniflix.svg'
import Evmos from '../assets/elements/SVG ASSETS/evmos.svg'
import Firmachain from '../assets/elements/SVG ASSETS/firmachain.svg'
import Kujira from '../assets/elements/SVG ASSETS/kujira.svg'
import Sei from '../assets/elements/SVG ASSETS/sei.svg'
import Dymension from '../assets/elements/SVG ASSETS/dymension.svg'
import Injective from '../assets/elements/SVG ASSETS/injective.svg'
import Nois from '../assets/elements/SVG ASSETS/nois.svg'
import Nolus from '../assets/elements/SVG ASSETS/nolus.svg'

export const iconsDictionary = {
    'Juno': Juno,
    'Stargaze': Stargaze,
    'KI': Ki,
    'Chihuahua': Chihuahua,
    'Omniflix': Omniflix,
    'Evmos': Evmos,
    'Firmachain': Firmachain,
    'Kujira': Kujira,
    'Sei': Sei,
    'Dymension': Dymension,
    'Injective': Injective,
    'Nois': Nois,
    'Nolus': Nolus
}