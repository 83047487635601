import { useState, useEffect, useRef } from 'react';
import TextBox from '../components/TextBox';
import { Link } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import { Card } from '@material-tailwind/react';
import LinkIcon from '../assets/elements/SVG ASSETS/Link.svg';
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";
import PopoverHeader from "@material-tailwind/react/PopoverHeader";
import cryptos from '../utility/cryptos.json'
import { iconsDictionary } from '../utility/iconsDictionary';
import { useParams } from 'react-router-dom'

import Social from '../components/Social';


export default function Details() {

    const { blockchainName } = useParams()

    const buttonRef = useRef();
    const [descriptionHead, setDescriptionHead] = useState([])
    const [crypto, setCrypto] = useState({
        blockchain_name: "",
        blockchain_logo: "",
        expected_commission_rate: "",
        commission: "",
        payout_frequency: "",
        unbonding_period: "",
        validation_address: "",
        stake_now_button_link: "",
        description_heading: "",
        description_heading_2: "",
        description_short: "",
        description_full: "",
        APRStatus: 1,
        stake: true,
        liveNetwork: "/"
    })

    useEffect(() => {
        let indexObject = 0;
        cryptos.map((e, index) => {
            if (e.blockchain_name === blockchainName) {
                setCrypto(e)
                indexObject = index
                return true
            }
            return false;
        })
        let flag = 0
        let index = []
        for (let i = 0; i === 0;) {
            let value = cryptos[indexObject].description_heading.indexOf('.', flag)
            if (value !== -1) {
                index.push(cryptos[indexObject].description_heading.substring(flag, value + 1))
                flag = value + 1;
            }
            else {
                i = -1
            }
        }
        setDescriptionHead(index)
    }, [blockchainName])
    return (
        <div className="App">
            <div className='container-img pb-10 pt-0 lg:pt-10'>
                <div className='flex flex-col lg:flex-row items-center titles-container'>
                    <div className='head-details w-full'>
                        <img className='icon-details' src={iconsDictionary[crypto.blockchain_logo]} alt='icon-img' />
                        <div className='titles-max-details text-left'>
                            <p className="titles-yellow-max-details">{crypto.blockchain_name.toUpperCase()}</p>
                            <br />
                            {"NETWORK "}
                            <br />
                        </div>
                        <div className='sub-title-white-details text-left'>
                            {crypto.description_short}
                        </div>
                    </div>
                    <div className='flex flex-col justify-center cards-details'>
                        <TextBox title="Expected Reward Rate" text={crypto.expected_commission_rate} />
                        <TextBox title="Commision" text={crypto.commission} />
                        <TextBox title="Payout Frequency" text={crypto.payout_frequency} />
                        <TextBox title="Unbonding Period" text={crypto.unbonding_period} />
                        <div className="flex justify-center container-text-box mb-2">
                            <Card className="text-box-address flex justify-between items-center">
                                Validator Address
                                <button ref={buttonRef} onClick={() => { navigator.clipboard.writeText(crypto.validation_address) }}>
                                    <img src={LinkIcon} alt='link' />
                                </button>
                                <Popover placement="top" ref={buttonRef}>
                                    <PopoverContainer>
                                        <PopoverHeader className='white'>Copied to clipboard</PopoverHeader>
                                    </PopoverContainer>
                                </Popover>
                            </Card>
                        </div>
                        <Link className='flex justify-center' target="blank" to={crypto.stake_now_button_link}>
                            <Button
                                buttonType="filled"
                                size="lg"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                className='button-yellow'
                            >
                                Stake now
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='container-titles-details'>
                <div className='flex flex-col justify-start container-titles-details-2'>
                    <p className='text-left pb-5 p-sub-grey flex flex-col lg:flex-row'>
                        {descriptionHead.map((e, index) => {
                            if (index % 2 === 0) {
                                return <p className='text-left yellow pr-2'>{e}</p>
                            }
                            else {
                                return <p className='text-left pr-2'>{e}</p>
                            }
                        })}
                    </p>
                    <p className='text-left p1-gray'>{crypto.description_heading_2}</p>
                </div>
            </div>
            <div className='container-bg-details container-titles-details-down'>
                <div className='flex flex-col lg:flex-row text-details'>
                    <div className='text-center lg:text-left px-10 lg:px-0  text-details short-description'>
                        {crypto.description_short}
                    </div>
                    <div className='flex text-left text-details long-description' >
                        {crypto.description_full}
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row'>
                    <div className='text-center lg:text-left px-10 lg:px-0  text-details short-description pt-10 hidden-component '>
                    </div>
                    <div id='ContactUs' className='container-contact-details' >
                        <div className='text-center lg:text-left titles-max-contact-details'>
                            {"GET "}
                            <br className='hidden-component-contact' />
                            {"IN "}
                            <p className="titles-yellow-max">{"TOUCH"}</p>
                            <br className='' />
                            {" WITH US "}
                        </div>
                        <div className='sub-title-gray-details text-center lg:text-left'>
                            Kingnodes are highly active in the communities we operate in. We are available 24/7 and provide free support helping community in ofﬁcial chats
                        </div>
                        <div className='flex justify-contact-items justify-start contact-items'>
                            <Social />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
