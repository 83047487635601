import { useState, useEffect } from 'react';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import NavLink from '@material-tailwind/react/NavLink';
import { NavItem } from '@material-tailwind/react';
import Logo from "../assets/logo/LogoKingN.svg";
import DropdownBox from "./DropdownBox";
import MenuIcon from '../assets/elements/SVG ASSETS/MenuHamb.svg'
import MenuCloseIcon from '../assets/elements/SVG ASSETS/MenuHamb_close.svg'
import cryptos from '../utility/cryptos.json'

import Social from './Social';
import DropItem from './DropItem';

function Header() {

  const [openNavbar, setOpenNavbar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleKeyDown = (event) => {
        if(event.target.id!=='openNav'){
            setOpenNavbar(false)
        }
      };
    
      useEffect(() => {
        window.scroll(0,0);
        window.addEventListener('click', handleKeyDown);
    
        return () => {
          window.removeEventListener('click', handleKeyDown);
        };
      }, []);

  return (<>
    <Navbar className="nav-bg grid grid-cols-1 lg:grid-cols-6 gap-4 navbar-container" navbar>
      <NavbarWrapper className='flex col-span-2 logo-header pr-5 lg:pr-0'>
        <a
          href="/"
          rel="noreferrer"
          className='logo-container'
        >
          <img className='logo' src={Logo} alt='medium' />
        </a>

        <button id='openNav' className={`hidden-component-revert ${openNavbar?'menu-button':''}`} onClick={() => setOpenNavbar(!openNavbar)}>
          <img id='openNav' src={openNavbar ? MenuCloseIcon : MenuIcon} alt="menuHamb" />
        </button>
      </NavbarWrapper>
      <NavbarCollapse open={openNavbar} className="col-span-4 flex flex-col-reverse lg:flex-row nav-collapse" icon={Logo}>
        <div className='flex-1 full-web-kit'>
          <div className='flex flex-col lg:flex-row justify-around nav-container-buttons'>
            <NavLink
              className='text-base nav-button'
              href="/#Benefits"
              rel="noreferrer"
              ripple="light"
            >
              <button className='text-base text-center'>Our Benefits</button>
            </NavLink>
            <NavItem id='openNav' onClick={(e) => { setDropdownOpen(!dropdownOpen) }} ripple="light">
              <button id='openNav' className='text-base'>Networks</button>
            </NavItem>
            <div className='hidden-component-revert pr-5'>
              {cryptos.map(e => {
                return <DropItem key={e.blockchain_name} name={e.blockchain_name} description={e.description_short} APR={e.expected_commission_rate} src={e.blockchain_logo} APRStatus={e.APRStatus} stake={e.stake} link={e.stake_now_button_link}  />
              })}
            </div>
            <NavLink
              className='text-base nav-button'
              href="/#ContactUs"
              rel="noreferrer"
              ripple="light"
            >
              <button className='text-base'>Contact Us</button>
            </NavLink>
          </div>
        </div>
        <div className='flex flex-2 xl:flex-1 nav-links justify-contact-items header-items'>
          <Social />
        </div>
      </NavbarCollapse>
    </Navbar>
    <DropdownBox setOpen={setDropdownOpen} open={dropdownOpen} />
  </>
  );
}

export default Header;
